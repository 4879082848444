import { DateTime } from "luxon";

export type OrderRule = "FIFO" | "LIFO";

export interface Transaction {
  ConfirmationNumber: string;
  OrderNumber: string;
  TradeDate: DateTime;
  BuySell: string;
  Security: string;
  Units: number;
  AveragePrice: number;
  Brokerage: number;
}

interface CgtDetail {
  id: string;
  confirmationNumber: string;
  sellDate: string;
  security: string;
  unitsSold: number;
  capitalGainLoss: number;
}

export interface CgtResults {
  [key: string]: CgtDetail[];
}

function calculateCgtForTransaction(
  buyPrice: number,
  sellPrice: number,
  units: number,
  brokerageBuy: number,
  brokerageSell: number
): number {
  const totalBuyCost = buyPrice * units + brokerageBuy;
  const totalSellRevenue = sellPrice * units - brokerageSell;
  return totalSellRevenue - totalBuyCost;
}

const getFinancialYear = (date: DateTime) => {
  const year = date.get("year");
  return date.get("month") >= 7 ? `FY${year + 1}` : `FY${year}`;
};

const applyOrderRule = (
  transactions: Transaction[],
  orderRule: OrderRule,
  currentDate: DateTime
) => {
  if (orderRule === "FIFO") {
    return transactions.sort(
      (a, b) => a.TradeDate.toMillis() - b.TradeDate.toMillis()
    );
  }

  if (orderRule === "LIFO") {
    return transactions
      .filter((t) => t.TradeDate <= currentDate)
      .sort((a, b) => b.TradeDate.toMillis() - a.TradeDate.toMillis());
  }

  return transactions;
};

export const calculateCGT = (
  transactions: Transaction[],
  orderRule: OrderRule = "FIFO"
) => {
  // Sort transactions by date, earliest first
  transactions.sort((a, b) => a.TradeDate.toMillis() - b.TradeDate.toMillis());

  const financialYears: { [key: string]: CgtDetail[] } = {};

  const buyTransactions: Transaction[] = [];
  const remainingUnits: { [key: string]: number } = {};

  for (const transaction of transactions) {
    if (transaction.BuySell === "B") {
      buyTransactions.push(transaction);
      const key = `${
        transaction.Security
      }-${transaction.TradeDate.toUnixInteger()}`;
      if (!remainingUnits[key]) {
        remainingUnits[key] = transaction.Units;
      } else {
        remainingUnits[key] += transaction.Units;
      }
    }
  }

  for (const transaction of transactions) {
    if (transaction.BuySell === "S") {
      const financialYear = getFinancialYear(transaction.TradeDate);
      if (!financialYears[financialYear]) {
        financialYears[financialYear] = [];
      }
      let unitsToMatch = transaction.Units;
      let totalCgt = 0;

      const sortedBuyTransactions = applyOrderRule(
        buyTransactions,
        orderRule,
        transaction.TradeDate
      );

      for (const buy of sortedBuyTransactions) {
        if (buy.Security === transaction.Security && unitsToMatch > 0) {
          const key = `${buy.Security}-${buy.TradeDate.toUnixInteger()}`;
          const availableUnits = remainingUnits[key];

          if (availableUnits > 0) {
            const unitsSold = Math.min(availableUnits, unitsToMatch);
            remainingUnits[key] -= unitsSold;
            unitsToMatch -= unitsSold; // Reduce unitsToMatch

            const cgt = calculateCgtForTransaction(
              buy.AveragePrice,
              transaction.AveragePrice,
              unitsSold,
              (buy.Brokerage / buy.Units) * unitsSold,
              (transaction.Brokerage / transaction.Units) * unitsSold
            );

            totalCgt += cgt;
          }
        }
      }

      financialYears[financialYear].push({
        id: transaction.ConfirmationNumber,
        confirmationNumber: transaction.ConfirmationNumber,
        sellDate: transaction.TradeDate.toLocaleString(),
        security: transaction.Security,
        unitsSold: transaction.Units,
        capitalGainLoss: Math.round(totalCgt * 100) / 100,
      });
    }
  }

  return financialYears;
};
