import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { HelpOutline } from "@mui/icons-material";
import Papa from "papaparse";
import { DateTime } from "luxon";
import { Transaction } from "./services/calculateCGT";
import { Results } from "./Results";
import { analytics } from "./Firebase";
import { logEvent } from "firebase/analytics";

function App() {
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);

  // Handle CSV file upload and calculate CGT.
  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      logEvent(analytics, "file_uploaded");
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const transactions = results.data.map((row: any) => ({
            ConfirmationNumber: row["Confirmation Number"],
            OrderNumber: row["Order Number"],
            TradeDate: DateTime.fromFormat(row["Trade Date"], "d/M/y"),
            Security: row["Security"],
            BuySell: row["Buy/ Sell"],
            Units: parseFloat(row["Units"]),
            AveragePrice: parseFloat(row["Average Price ($)"]),
            Brokerage: parseFloat(row["Brokerage (inc GST.)"]),
          }));

          setTransactions(transactions);
        },
      });
    }
  };

  return (
    <Box textAlign="center">
      <CssBaseline />
      <Box p={2}>
        <Typography variant="h3">Australian Stock CGT Calculator</Typography>
        <Box p={2}>
          <Typography>
            Just a simple tool to work out capital gains/losses of stock sales
            for ATO tax return purpose. Compare FIFO or LIFO calculation method
            and get best tax return outcome. 📈📉
          </Typography>
          <Typography>🎉 It's free!</Typography>
        </Box>
      </Box>
      <Box p={2}>
        <input
          accept=".csv"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFile}
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Upload
          </Button>
          <Typography display="inline" p={1} variant="caption">
            Upload your CommSec confirmation CSV file including ALL past years
            records.
            <Tooltip
              title="Only support CommSec at moment. Login to your CommSec account, then Trading &gt; Confirmations &gt; Select all past years &gt;
              Download CSV"
            >
              <IconButton>
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </Typography>
        </label>
      </Box>

      <Results transactions={transactions} />
    </Box>
  );
}

export default App;
