import { useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import InfoIconOutlined from "@mui/icons-material/InfoOutlined";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  calculateCGT,
  CgtResults,
  OrderRule,
  Transaction,
} from "./services/calculateCGT";
import { logEvent } from "firebase/analytics";
import { analytics } from "./Firebase";
import React from "react";

export const Results = ({ transactions }: { transactions: Transaction[] }) => {
  const [results, setResults] = React.useState<CgtResults>({});
  const [orderRule, setOrderRule] = React.useState<OrderRule>("FIFO");
  const handleOrderRuleChange = (event: SelectChangeEvent<"FIFO" | "LIFO">) => {
    setOrderRule(event.target.value as OrderRule);
  };

  useEffect(() => {
    if (transactions.length > 0) {
      setResults(calculateCGT(transactions, orderRule));
    }
  }, [transactions, orderRule]);

  const columns: GridColDef[] = [
    {
      field: "confirmationNumber",
      headerName: "Confirmation Number",
      width: 150,
    },
    { field: "sellDate", headerName: "Sell Date", width: 130 },
    { field: "security", headerName: "Code", width: 130 },
    { field: "unitsSold", headerName: "Units Sold", width: 130 },
    {
      field: "capitalGainLoss",
      headerName: "Capital Gain/Loss ($)",
      width: 160,
    },
  ];

  useEffect(() => {
    logEvent(analytics, "results_displayed");
  }, [results]);

  return (
    <Box p={4}>
      {results && Object.keys(results).length > 0 && (
        <Box>
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              Calculation method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderRule}
              label="Calculation method"
              onChange={handleOrderRuleChange}
            >
              <MenuItem value={"FIFO"}>FIFO (First in first out)</MenuItem>
              <MenuItem value={"LIFO"}>LIFO (Last in first out)</MenuItem>
            </Select>
          </FormControl>
          {Object.keys(results)
            .sort()
            .reverse()
            .map((financialYear) => {
              const cgtDetails = [...results[financialYear]].reverse();

              return (
                <Box key={financialYear} mb={5}>
                  <Box py={2}>
                    <Typography variant="h5">{financialYear}</Typography>
                  </Box>
                  <Box>
                    <DataGrid
                      rows={cgtDetails}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 50 },
                        },
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          <Box p={2}>
            <Typography variant="caption" p={1}>
              <InfoIconOutlined fontSize="small" sx={{ mx: 1 }} />
              Brokerage cost is included in the calculation. Please note that
              this tool is only a guide and does not replace professional
              advice.
            </Typography>
          </Box>
          <Box p={1}>
            <Stack spacing={1}>
              <Link
                href="https://www.buymeacoffee.com/edisonwang"
                target="_blank"
              >
                <Box
                  component="img"
                  sx={{
                    height: 60,
                    width: 217,
                  }}
                  alt="Buy Me A Coffee"
                  src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
                />
              </Link>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
};
